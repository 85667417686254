import { env } from '../../environments/environment';
import { EcardResponse } from '../../models';
import { apiLang } from '../locale';

export async function getEcardOptions(): Promise<EcardResponse> {
  const response = await fetch(`${env.apiUrl}/ecards`, {
    headers: {
      'Accept-Language': apiLang(),
    },
  });

  const options: EcardResponse = await response.json();

  return options;
}
