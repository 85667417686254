let locale;

export enum Locale {
  English = 'en-CA',
  French = 'fr-CA',
}

export function getLocale(): string {
  if (!locale) {
    locale = document.documentElement.lang.toLowerCase().startsWith('fr') ? Locale.French : Locale.English;
  }

  return locale;
}

export function apiLang(): string {
  if (!locale) {
    getLocale();
  }

  switch (locale) {
    case Locale.English: {
      return 'en';
    }
    case Locale.French: {
      return 'fr';
    }
  }
}
