export enum CanadianProvince {
  Alberta = 'AB',
  BritishColumbia = 'BC',
  Manitoba = 'MB',
  NewBrunswick = 'NB',
  Newfoundland = 'NL',
  NovaScotia = 'NS',
  NorthwestTerritories = 'NT',
  Nunavut = 'NU',
  Ontario = 'ON',
  PEI = 'PE',
  Quebec = 'QC',
  Saskatchewan = 'SK',
  Yukon = 'YT',
}

export const CanadianProvinceDictionary: { [key in CanadianProvince]: string } = {
  [CanadianProvince.Alberta]: 'Alberta',
  [CanadianProvince.BritishColumbia]: 'British Columbia',
  [CanadianProvince.Manitoba]: 'Manitoba',
  [CanadianProvince.NewBrunswick]: 'New Brunswick',
  [CanadianProvince.Newfoundland]: 'Newfoundland and Labrador',
  [CanadianProvince.NovaScotia]: 'Nova Scotia',
  [CanadianProvince.NorthwestTerritories]: 'Northwest Territories',
  [CanadianProvince.Nunavut]: 'Nunavut',
  [CanadianProvince.Ontario]: 'Ontario',
  [CanadianProvince.PEI]: 'Prince Edward Island',
  [CanadianProvince.Quebec]: 'Quebec',
  [CanadianProvince.Saskatchewan]: 'Saskatchewan',
  [CanadianProvince.Yukon]: 'Yukon',
};

export const Provinces: { label: string; value: CanadianProvince }[] = Object.keys(CanadianProvinceDictionary).map(key => ({
  label: CanadianProvinceDictionary[key] as string,
  value: key as CanadianProvince,
}));
