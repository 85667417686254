export enum USState {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
  DC = 'DC',
  AmericanSamoa = 'AS',
  Guam = 'GU',
  NorthernMarianaIslands = 'MP',
  PuertoRico = 'PR',
  VirginIslands = 'VI',
}

export const USStateDictionary: { [key in USState]: string } = {
  [USState.Alabama]: 'Alabama',
  [USState.Alaska]: 'Alaska',
  [USState.Arizona]: 'Arizona',
  [USState.Arkansas]: 'Arkansas',
  [USState.California]: 'California',
  [USState.Colorado]: 'Colorado',
  [USState.Connecticut]: 'Connecticut',
  [USState.Delaware]: 'Delaware',
  [USState.Florida]: 'Florida',
  [USState.Georgia]: 'Georgia',
  [USState.Hawaii]: 'Hawaii',
  [USState.Idaho]: 'Idaho',
  [USState.Illinois]: 'Illinois',
  [USState.Indiana]: 'Indiana',
  [USState.Iowa]: 'Iowa',
  [USState.Kansas]: 'Kansas',
  [USState.Kentucky]: 'Kentucky',
  [USState.Louisiana]: 'Louisiana',
  [USState.Maine]: 'Maine',
  [USState.Maryland]: 'Maryland',
  [USState.Massachusetts]: 'Massachusetts',
  [USState.Michigan]: 'Michigan',
  [USState.Minnesota]: 'Minnesota',
  [USState.Mississippi]: 'Mississippi',
  [USState.Missouri]: 'Missouri',
  [USState.Montana]: 'Montana',
  [USState.Nebraska]: 'Nebraska',
  [USState.Nevada]: 'Nevada',
  [USState.NewHampshire]: 'New Hampshire',
  [USState.NewJersey]: 'New Jersey',
  [USState.NewMexico]: 'New Mexico',
  [USState.NewYork]: 'New York',
  [USState.NorthCarolina]: 'North Carolina',
  [USState.NorthDakota]: 'North Dakota',
  [USState.Ohio]: 'Ohio',
  [USState.Oklahoma]: 'Oklahoma',
  [USState.Oregon]: 'Oregon',
  [USState.Pennsylvania]: 'Pennsylvania',
  [USState.RhodeIsland]: 'Rhode Island',
  [USState.SouthCarolina]: 'South Carolina',
  [USState.SouthDakota]: 'South Dakota',
  [USState.Tennessee]: 'Tennessee',
  [USState.Texas]: 'Texas',
  [USState.Utah]: 'Utah',
  [USState.Vermont]: 'Vermont',
  [USState.Virginia]: 'Virginia',
  [USState.Washington]: 'Washington',
  [USState.WestVirginia]: 'West Virginia',
  [USState.Wisconsin]: 'Wisconsin',
  [USState.Wyoming]: 'Wyoming',
  [USState.DC]: 'District of Columbia',
  [USState.AmericanSamoa]: 'American Samoa',
  [USState.Guam]: 'Guam',
  [USState.NorthernMarianaIslands]: 'Northern Mariana Islands',
  [USState.PuertoRico]: 'Puerto Rico',
  [USState.VirginIslands]: 'Virgin Islands, U.S.',
};

export const States: { label: string; value: USState }[] = Object.keys(USStateDictionary).map(key => ({
  label: USStateDictionary[key] as string,
  value: key as USState,
}));
