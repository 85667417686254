<script>
  import * as Models from '../../models';
  import { t } from '../../utils';
  import { amount, isMonthly, activeStep, steps, amounts } from '../state.store';

  import AmountButton from '../components/AmountButton.svelte';
  import AmountInput from '../components/AmountInput.svelte';

  export let title;
  export let intro;
</script>

<div class="uwdonation__row">
  <div class="uwdonation__col uwdonation__title-group">
    <h1 class="uwdonation__title">{title}</h1>
    <p class="uwdonation__lead">{intro}</p>
  </div>
</div>

<form on:submit|preventDefault={() => activeStep.set(steps.Options)}>
  <div class="uwdonation__row">
    <div class="uwdonation__col">
      <div class="uwdonation__amount-button-group">
        {#each amounts as amount}
          <AmountButton {amount} />
        {/each}
        <AmountInput {amounts} />
      </div>
    </div>

    {#if $amount < 5}
      <div style="font-size: smaller; color: #e2211c; text-align: center; font-weight: bold;">
        {@html t('Our minimum online donation amount is $5.00.')}
      </div>
    {/if}
  </div>

  <div class="uwdonation__row">
    <div class="uwdonation__col">
      <div class="uwdonation__form-check">
        <input type="checkbox" id="isMonthly" name="isMonthly" bind:checked={$isMonthly} />
        <label class="form-check-label" for="isMonthly">
          <b>
            {@html t('Make it monthly, make it easy.')}
          </b>
          {@html t('With an automatic monthly gift, you’re making an even greater impact in our community. You can always change or stop your monthly giving at any time.')}
        </label>
      </div>
    </div>
  </div>

  <div class="uwdonation__actions">

    <button disabled={$amount < 5} class="uwdonation__next-step-button uwdonation__donate-button" type="submit">
      <span>
        {@html t('Donate')}
      </span>
      <img
        class="uwdonation__next-arrow"
        src="data:image/svg+xml;base64,
        PHN2ZyBpZD0iZTQ5ODgzNDEtODYwZC00ZTNjLThjNTctZWQ0MDFmZTUzMjg2IiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjAuMzZpbiIgaGVpZ2h0PSIwLjIxaW4iIHZpZXdCb3g9IjAgMCAyNi4yNSAxNSI+PGRlZnM+PHN0eWxlPi5hMjM1NWEzNy0zNjIyLTQ1N2MtODFmNS0zMWQ0ZWYxMDkwY2J7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48dGl0bGU+YXJyb3ctd2hpdGU8L3RpdGxlPjxwYXRoIGNsYXNzPSJhMjM1NWEzNy0zNjIyLTQ1N2MtODFmNS0zMWQ0ZWYxMDkwY2IiIGQ9Ik0xOC42LDcuNzFsLS40MS40MWEuNjkuNjksMCwwLDAsMCwxTDIzLjEsMTRoLTIyYS43LjcsMCwwLDAtLjcuNzF2LjU4YS43LjcsMCwwLDAsLjcuNzFoMjJsLTQuOTEsNC44OWEuNjkuNjksMCwwLDAsMCwxbC40MS40MWEuNjkuNjksMCwwLDAsMSwwbDYuODMtNi43OWEuNzIuNzIsMCwwLDAsMC0xTDE5LjU5LDcuNzFBLjY5LjY5LDAsMCwwLDE4LjYsNy43MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0wLjM4IC03LjUpIi8+PC9zdmc+"
        alt="Next" />
    </button>
  </div>
</form>
