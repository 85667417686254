import { amounts } from '../app/state.store';
import { env } from '../environments/environment';
import { Donation } from '../models';
import { uuidv4 } from './uuidv4';

export function analytics(campaignId: string, donation: Donation) {
  const displayInterval = donation.attributes.interval.toLowerCase() === 'none' ? 'One Time' : 'Monthly';
  const displayName = `${displayInterval} Donation — ${amounts.find(amt => amt === donation.attributes.amount) || 'Other'}`;

  let category = 'Normal';
  if (donation.attributes.isCorporate && donation.attributes.inTribute) {
    category = 'Corporate & In Memorial';
  } else if (donation.attributes.isCorporate) {
    category = 'Corporate';
  } else if (donation.attributes.inTribute) {
    category = 'In Memorial';
  }

  const donationTransation = {
    event: 'DonationTransaction',
    transactionId: uuidv4(),
    transactionAffiliation: `Donation Campaign: ${campaignId}`,
    transactionTotal: donation.attributes.amount,
    shipping: 0,
    tax: 0,
    transactionProducts: [
      {
        sku: displayName,
        name: displayName,
        category: category,
        price: donation.attributes.amount,
        quantity: 1,
      },
    ],
  };

  const donationComplete = {
    event: 'DonationComplete',
    Campaign: campaignId,
    DonationAmount: donation.attributes.amount,
    Interval: donation.attributes.interval,
    IsCorporate: donation.attributes.isCorporate,
    IsTribute: donation.attributes.inTribute,
  };

  if (env.production === false) {
    console.log('dataLayer:DonationTransaction :::', donationTransation);
    console.log('dataLayer:DonationComplete :::', donationComplete);
  }

  const dataLayer = window.dataLayer || [];

  dataLayer.push(donationTransation);
  dataLayer.push(donationComplete);
}
