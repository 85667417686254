<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import { amount as donationAmount } from '../state.store';

  export let amounts = [];
  let amount = undefined;

  onMount(() => {
    amount = amounts.find(a => a === $donationAmount) ? null : $donationAmount;
  });

  function onChange() {
    // Round amount to nearest integer
    amount = amount || 0;

    if (Number.isInteger(amount) === false) {
      amount = Math.floor(amount);
    }

    donationAmount.set(amount);
  }
</script>

<input
  class="uwdonation__amount-input"
  bind:value={amount}
  on:focus={onChange}
  on:keyup={onChange}
  on:blur={onChange}
  class:active={amount === $donationAmount}
  min="5"
  step="1"
  type="number"
  placeholder="$" />
