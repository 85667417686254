<script>
  import { t, apiLang } from '../../utils';
  import AppConfig from '../config/app.config';

  import BottomBar from './BottomBar.svelte';
  import Logo from './Logo.svelte';

  const brand = AppConfig.brand[apiLang()];
  const contact = AppConfig.contact[apiLang()];
</script>

<div class="uwdonation__footer-cont">
  <div class="uwdonation-footer">
    <p>
      {@html t('This site is protected by reCAPTCHA and the Google ')}
      <a href="https://policies.google.com/policy">
        {@html t('Privacy Policy')}
      </a>
      {@html t(' and ')}
      <a href="https://policies.google.com/terms">
        {@html t('Terms of Service')}
      </a>
      {@html t(' apply.')}
    </p>
    <div class="uwdonation-flex-columns-wrapper uwdonation__row uwdonation-footer-links-wrapper">
      <div class="uwdonation-flex-column">
        <p>
          <span class="uwdonation-caps">
            <b>
              {@html brand.alt}
            </b>
          </span>
          <br />
          <span>
            {@html contact.address1}
          </span>
          <br />
          <span>
            {@html contact.address2}
          </span>
        </p>
      </div>
      <div class="uwdonation-flex-column">
        <p>
          <span class="uwdonation-caps">
            <b>
              {@html t('Need help?')}
            </b>
          </span>
          <br />
          <a href="mailto:{contact.email}">
            {@html contact.email}
          </a>
          <br />
          <a href="tel:{contact.phone}">
            {@html contact.phone}
          </a>
        </p>
      </div>
      <div class="uwdonation-flex-column">
        <p>
          <a href={t('https://unitedwayregina.ca/privacy-policy')} class="uwdonation-caps">
            <b>
              {@html t('Privacy')}
            </b>
          </a>
          <br />
          <a href={t('https://unitedwayregina.ca/donor-bill-of-rights')} class="uwdonation-caps">
            <b>
              {@html t('Donor Bill Of Rights')}
            </b>
          </a>
        </p>
      </div>
    </div>

    <div class="uwdonation-flex-columns-wrapper uwdonation__row uwdonation__logo-copyright">
      <div class="uwdonation-footer-logo-wrapper">
        <Logo />
      </div>
      <div class="uwdonation-footer-copyright">
        <p>
          <span>
            ©
            {@html new Date().getFullYear()}
            {@html brand.alt}
            .
            {@html t('All rights reserved.')}
          </span>
          <br />
          <span>
            {@html t('Charitable registration number: ')}
            {@html AppConfig.registrationNumber}
          </span>
        </p>
      </div>
    </div>

  </div>
</div>
