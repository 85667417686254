import { errors } from '../../app/state.store';
import { env } from '../../environments/environment';
import { Donation } from '../../models';
import { analytics } from '../analytics';
import { apiLang } from '../locale';

export async function postDonation(campaignId: string, donation: Donation): Promise<Response> {
  // Cleanup dedication since API rejects non-NULL values if not complete
  if (donation.attributes) {
    if (!donation.attributes.dedication.recipients_name) {
      donation.attributes.dedication.recipients_name = donation.attributes.dedication.in_memory_of;
    }

    if (!donation.attributes.dedication.send_date) {
      const d = new Date();
      donation.attributes.dedication.send_date = `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`;
    }

    if (!donation.attributes.inTribute) {
      donation.attributes.dedication = null;
      donation.attributes.shouldNotify = false;
    }

    if (!donation.attributes.isCorporate) {
      donation.attributes.workplace = null;
    }

    if (!donation.attributes.hasWorkplaceCampaign) {
      donation.attributes.workplace_campaign = null;
    }
  }

  donation.attributes['g-recaptcha-response'] = await grecaptcha
    .execute(env.recaptchaKey, { action: 'donate' })
    .then((token: string) => token)
    .catch(err => console.log(err));

  return fetch(`${env.apiUrl}/campaigns/${campaignId}/donations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': apiLang(),
    },
    body: JSON.stringify({ data: donation }),
  })
    .then(response => response.json())
    .then(res => {
      if (res.errors) {
        errors.set(res.errors.map(e => e.detail));
        throw res;
      } else {
        analytics(campaignId, donation);
        return res;
      }
    });
}
