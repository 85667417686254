import 'stripe-v3';
import App from './app/App.svelte';
import AppConfig from './app/config/app.config';
import { activeStep, amount, errors, isMonthly, slug, steps } from './app/state.store';
import { env } from './environments/environment';
import { DonationAttributes, Media } from './models';
import { Community, communityDictionary } from './models/community';
import { getCampaign, loadScript, parseParms } from './utils';
import { getEcardOptions } from './utils/api/get-ecard-options';

export { missingTranslations } from './utils';

interface Config {
  c: string;
  m: boolean;
  a: number;
  s: boolean;
  h: boolean;
  community: string;
  cause?: string;
}

declare global {
  interface Window {
    donationImage?: string;
    UWDonation?: any;
    ga?: any;
    dataLayer?: any;
  }
}

// Polyfills
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, rawPos) {
      var pos = rawPos > 0 ? rawPos | 0 : 0;
      return this.substring(pos, pos + search.length) === search;
    },
  });
}
//

// Track `app` as a singleton.
let app;
let styles = document.documentElement.style.cssText;

/**
 * Create or destroy the app based on the current hash
 *
 * @export
 * @param {Event} event
 */
export function toggle() {
  const hash = window.location.hash && window.location.hash.substring(1);
  const shouldLaunch: boolean = hash && hash.startsWith('donate');

  if (shouldLaunch) {
    loadScript(`https://www.google.com/recaptcha/api.js?render=${env.recaptchaKey}`, () =>
      loadScript('https://js.stripe.com/v3/', () => init(hash && hash.split('?')[1]))
    );
    document.documentElement.style.paddingRight = window.innerWidth - document.body.clientWidth + 'px';
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.documentElement.style.cssText = styles;
    if (app) {
      app.$destroy();
    }
  }
}

/**
 * Initialize the app with config derived from the querystring
 *
 * @export
 * @param {string} search
 */
export function init(search: string = '') {
  const params: { [key: string]: string } = parseParms(search);
  const amount = params['a'] ? parseInt(params['a'], 10) : NaN;
  const config: Config = {
    c: params.c,
    m: params['m'] === 'true',
    a: isNaN(amount) ? undefined : amount,
    s: params['s'] === 'true',
    h: params['h'] === 'true',
    cause: params['cause'],
    community: params['community'],
  };

  loadScript(
    'https://polyfill.io/v3/polyfill.min.js?features=Promise%2CPromise.prototype.finally%2Cfetch%2CString.prototype.startsWith',
    () => main(config)
  );

  // if (browserSupportsAllFeatures()) {
  //   main(config);
  // } else {
  //   loadScript('https://cdn.jsdelivr.net/npm/whatwg-fetch@3.0.0/dist/fetch.umd.js', () => main(config));
  // }
}

/**
 * Bootstrap
 *
 * @param {Config} { c = 'uwr', m = false, a = 25 }
 */

async function main({
  c = AppConfig.defaultCampaign,
  m = false,
  a = AppConfig.defaultAmount,
  s = false,
  h = false,
  cause,
  community = Community.Regina,
}: Config) {
  const target: HTMLElement = document.querySelector(AppConfig.targetSelector) || document.body;

  const campaign = await getCampaign(c);
  const ecardOptions = await getEcardOptions();

  let causes;
  if (campaign.data.attributes.show_causes) {
    causes = campaign.included.filter(i => i.type === 'causes');
  }

  let ecards;
  if (ecardOptions) {
    ecards = ecardOptions.data.reduce((g, ecard) => {
      if (!g[ecard.attributes.group]) {
        g[ecard.attributes.group] = [];
      }

      g[ecard.attributes.group].push(ecard);
      return g;
    }, {});

    ecards = Object.keys(ecards).map(key => ({ name: key, cards: ecards[key] })) || [];
  }

  const donation: DonationAttributes = {
    address1: null,
    amount: null,
    cause,
    city: null,
    community: community as Community,
    country: null,
    dedication: {
      in_memory_of: null,
      ecard: null,
      hide_gift_amount: false,
      notify_sender: false,
      recipients_email: null,
      recipients_name: null,
      send_date: null,
      senders_message: '',
      senders_name: null,
      type: 'none',
    },
    email: null,
    instructions: '',
    interval: null,
    inTribute: h,
    name: null,
    phone: null,
    postal_code: null,
    state: null,
    token: null,
    workplace_campaign: null,
    workplace: null,
    'g-recaptcha-response': null,
  };

  const media = campaign?.included
    .filter(m => m.type === 'media')
    .filter((m: Media) => m?.attributes?.name?.toLowerCase() === (window.donationImage || '').toLowerCase()) as Media[];
  const defaultMedia = campaign?.included
    .filter(m => m.type === 'media')
    .filter((m: Media) => m?.attributes?.name?.toLowerCase() === 'default') as Media[];

  const props = {
    attributes: campaign.data.attributes,
    causes: causes || [],
    ecards,
    bgImage: {
      mobile:
        media?.find(m => m?.attributes?.collection === 'mobile_campaigns')?.links?.self ||
        defaultMedia?.find(m => m?.attributes?.collection === 'mobile_campaigns')?.links?.self,
      default:
        media?.find(m => m?.attributes?.collection === 'campaigns')?.links?.self ||
        defaultMedia?.find(m => m?.attributes?.collection === 'campaigns')?.links?.self,
    },
    donation,
  };

  // Pre-load stores
  slug.set(campaign.data.attributes.slug);
  amount.set(a);
  isMonthly.set(m);
  activeStep.set(s ? steps.Options : steps.Amount);
  errors.set([]);

  // Replace existing app
  if (app) {
    app.$destroy();
  }

  app = new App({ target, props });
}

// Listen to relevant window events
window.addEventListener('load', toggle);
window.addEventListener('hashchange', toggle);

// Setup for local environments
if (env.local) {
  // window.location.href = '/#donate';
}
