export enum Country {
  Canada = 'CA',
  USA = 'US',
}

export const CountriesDictionary: { [key in Country]: string } = {
  [Country.Canada]: 'Canada',
  [Country.USA]: 'USA',
};

export const Countries: { label: string; value: Country }[] = Object.keys(CountriesDictionary).map(key => ({
  label: CountriesDictionary[key] as string,
  value: key as Country,
}));
