<script>
  import { env } from '../../environments/environment';
  import { communityDictionary } from '../../models/community';
  import { apiLang, t } from '../../utils';
  import Ecards from '../components/Ecards.svelte';
  import { activeStep, amount, steps } from '../state.store';

  export let donation;
  export let attributes;
  export let ecards;
  export let causes;

  let showPreview = false;

  $: previewSrc = `
    ${env.apiUrl}/ecards/${donation.dedication.ecard}/${
    donation.dedication.type === 'ecard' ? 'preview' : 'printpreview'
  }?language=${apiLang()}&amount=${$amount || ''}${
    donation.dedication.hide_gift_amount ? `&hide_gift_amount=${donation.dedication.hide_gift_amount}` : ''
  }${donation.dedication.in_memory_of ? `&in_memory_of=${donation.dedication.in_memory_of}` : ''}${
    donation.dedication.senders_message ? `&senders_message=${donation.dedication.senders_message}` : ''
  }${donation.dedication.senders_name ? `&senders_name=${donation.dedication.senders_name}` : ''}`;

  function onFormSubmit(event) {
    activeStep.set(steps.Info);
  }

  function updateTribute(type) {
    showPreview = false;

    if (donation.inTribute === false) {
      donation.dedication.type = 'none';
    } else {
      donation.dedication.type = type;
    }
  }

  function onChange() {
    showPreview = false;
  }
</script>

<form class="uwdonation__form" on:change={() => (showPreview = false)} on:submit|preventDefault={onFormSubmit}>

  <div class="uwdonation__form-group" style="display:{causes.length ? 'block' : 'none'}; margin-bottom: 2em;">
    <label for="cause">
      {@html t('Please direct my donation to:')}
    </label>
    <select name="cause" bind:value={donation.cause}>
      <option value={null}>
        {@html t('Where it is needed most')}
      </option>
      {#each causes as cause}
        <option value={cause.id}>{cause.attributes.description}</option>
      {/each}
    </select>
  </div>

  <div class="uwdonation__form-group" style="margin-bottom: 2em;">
    <label for="community">
      {@html t('What Community Would You Like to Direct Your Donation to?')}
    </label>
    <select name="community" bind:value={donation.community}>
      {#each Object.entries(communityDictionary) as community}
        <option value={community[0]}>{community[1]}</option>
      {/each}
    </select>
  </div>

  <div class="uwdonation__form-check">
    <input type="checkbox" id="hasWorkplaceCampaign" name="hasWorkplaceCampaign" bind:checked={donation.hasWorkplaceCampaign} />
    <label class="form-check-label" for="hasWorkplaceCampaign">
      <b>
        {@html t('Count my gift towards my workplace campaign.')}
      </b>
      {@html t('Include your workplace’s name to count your donation towards a specific workplace campaign.')}
    </label>
  </div>
  <div style="display:{donation.hasWorkplaceCampaign ? 'block' : 'none'}">
    <div class="uwdonation__form-group">
      <label for="workplace_campaign">
        {@html t('Workplace name:')}
      </label>
      <input
        type="text"
        maxlength="250"
        id="workplace_campaign"
        required={donation.hasWorkplaceCampaign}
        bind:value={donation.workplace_campaign} />
    </div>
  </div>

  <div class="uwdonation__form-check">
    <input type="checkbox" id="isCorporate" name="isCorporate" bind:checked={donation.isCorporate} />
    <label class="form-check-label" for="isCorporate">
      <b>
        {@html t('Make a corporate gift.')}
      </b>
      {@html t('Include your company name to make this gift on behalf of your organization.')}
    </label>
  </div>
  <div style="display:{donation.isCorporate ? 'block' : 'none'}">
    <div class="uwdonation__form-group">
      <label for="workplace">
        {@html t('Which company name should appear on the receipt?')}
      </label>
      <input type="text" maxlength="250" id="workplace" required={donation.isCorporate} bind:value={donation.workplace} />
    </div>
  </div>
  <hr />
  <div class="uwdonation__form-check" style="display:{attributes.tribute_cards ? 'block' : 'none'}">
    <input type="checkbox" id="inTribute" name="inTribute" bind:checked={donation.inTribute} on:change={() => updateTribute('ecard')} />
    <label class="form-check-label" for="inTribute">
      <b>
        {@html t('Give in honour of someone.')}
      </b>
      {@html t('On your behalf, we can send a card to the recipient notifying them of your generous gift.')}
    </label>
  </div>

  <!-- Gift Type -->
  <div style="display:{donation.inTribute ? 'block' : 'none'}">

    <div class="uwdonation-in-memory-delivery-options-selection">
      <h3>
        {@html t('How would you like to share your gift?')}
      </h3>
      <div class="uwdonation-flex-columns-wrapper">
        <button
          type="button"
          on:click={() => {
            updateTribute('ecard');
            donation.dedication.send_to = 'honouree';
          }}
          class:active={donation.dedication.type === 'ecard'}
          class="uwdonation-in-memory-delivery-options__button uwdonation__col-50">
          <span class="uwdonation-in-memory-delivery-options-heading">{t('Email a card')}</span>
          <span class="uwdonation-in-memory-delivery-options-description">{t("Send an e-card straight to someone's inbox.")}</span>
        </button>
        <button
          type="button"
          on:click={() => {
            updateTribute('print');
            donation.dedication.sent_to = null;
          }}
          class:active={donation.dedication.type === 'print'}
          class="uwdonation-in-memory-delivery-options__button uwdonation__col-50">
          <span class="uwdonation-in-memory-delivery-options-heading">{t('Print a card')}</span>
          <span class="uwdonation-in-memory-delivery-options-description">
            {t('Print a card and give it personally or send it by mail.')}
          </span>
        </button>
        <button
          type="button"
          on:click={() => {
            updateTribute('none');
            donation.dedication.sent_to = null;
          }}
          class:active={donation.dedication.type === 'none'}
          class="uwdonation-in-memory-delivery-options__button uwdonation__col-50">
          <span class="uwdonation-in-memory-delivery-options-heading">{t('No Card Needed')}</span>
          <span class="uwdonation-in-memory-delivery-options-description">
            {t('Honour someone without sending a card. Notify United Way.')}
          </span>
        </button>
      </div>
    </div>

    <!-- No card -->
    <div style="display:{donation.dedication.type === 'none' ? 'block' : 'none'}">

      <h3>
        {@html t('Who do you want to honour?')}
      </h3>

      <div class="uwdonation__form-group">
        <label for="inHonourOf">
          {@html t('In honour of')}
        </label>
        <input
          on:keydown={onChange}
          type="text"
          maxlength="100"
          id="inHonourOf"
          required={donation.inTribute}
          bind:value={donation.dedication.in_memory_of}
          placeholder={t("Honouree's full name")} />
      </div>

      <div class="uwdonation__form-group">
        <label for="ecardFrom">
          {@html t('From')}
        </label>
        <input
          on:keydown={onChange}
          type="text"
          id="ecardFrom"
          maxlength="100"
          required={donation.inTribute}
          bind:value={donation.dedication.senders_name}
          placeholder={t('Enter name')} />
      </div>

      <div class="uwdonation__form-group">
        <textarea
          on:keydown={onChange}
          id="comments"
          maxlength="250"
          bind:value={donation.dedication.senders_message}
          placeholder={t('Include a custom message (optional)')} />
        <small class="uwdonation__label-extra-help">{t('250 characters maximum')}</small>
      </div>
    </div>

    <!-- Ecard -->
    <div style="display:{donation.dedication.type === 'ecard' ? 'block' : 'none'}">

      <h3>
        {@html t('What message should we include with your card?')}
      </h3>

      <div class="uwdonation__form-group">
        <label for="inHonourOf">
          {@html t('In honour of')}
        </label>
        <input
          on:keydown={onChange}
          type="text"
          maxlength="100"
          id="inHonourOf"
          required={donation.inTribute}
          bind:value={donation.dedication.in_memory_of}
          placeholder={t("Honouree's full name")} />
      </div>

      <div class="uwdonation__form-group">
        <label for="ecardFrom">
          {@html t('From')}
        </label>
        <input
          on:keydown={onChange}
          type="text"
          id="ecardFrom"
          maxlength="100"
          required={donation.inTribute}
          bind:value={donation.dedication.senders_name}
          placeholder={t('Enter name')} />
      </div>

      <div class="uwdonation__form-group">
        <textarea
          on:keydown={onChange}
          id="comments"
          maxlength="250"
          bind:value={donation.dedication.senders_message}
          placeholder={t('Include a custom message (optional)')} />
        <small class="uwdonation__label-extra-help">{t('250 characters maximum')}</small>
      </div>

      <div class="uwdonation__form-check">
        <input type="checkbox" id="hideGiftAmount" name="hideGiftAmount" bind:checked={donation.dedication.hide_gift_amount} />
        <label class="form-check-label" for="hideGiftAmount">
          <b>
            {@html t('Please hide the gift amount.')}
          </b>
        </label>
      </div>

      <h3>
        {@html t('Where should we deliver the e-card?')}
      </h3>

      <div class="uwdonation__form-radio">
        <input type="radio" id="sendToHonouree" value="honouree" bind:group={donation.dedication.send_to} />
        <label class="form-radio-label" for="sendToHonouree">
          <b>
            {@html t('Send to ')}
            {donation.dedication.in_memory_of || t('honouree')}
          </b>
        </label>
      </div>

      <div class="uwdonation__form-radio">
        <input type="radio" id="sendToSomeoneElse" value="someoneElse" bind:group={donation.dedication.send_to} />
        <label class="form-radio-label" for="sendToSomeoneElse">
          <b>
            {@html t('Send to someone else')}
          </b>
        </label>
      </div>

      <div style="display:{donation.dedication.send_to ? 'block' : 'none'}">
        <div class="uwdonation__form-group" style="display:{donation.dedication.send_to === 'someoneElse' ? 'block' : 'none'}">
          <label for="someoneElseName">
            {@html t('Name')}
          </label>
          <input
            on:keydown={onChange}
            type="text"
            maxlength="100"
            id="someoneElseName"
            required={donation.inTribute && donation.dedication.send_to === 'someoneElse'}
            bind:value={donation.dedication.recipients_name}
            placeholder={t('Enter name')} />
        </div>
        <div class="uwdonation__form-group">
          <label for="sendToEmail">
            {@html t('Email')}
          </label>
          <input
            on:keydown={onChange}
            type="text"
            maxlength="100"
            id="sendToEmail"
            required={donation.inTribute && donation.dedication.type === 'ecard' && donation.dedication.send_to}
            bind:value={donation.dedication.recipients_email}
            placeholder={t('Enter email')} />
        </div>
      </div>

      <hr />

      <h3>
        <label for="send-now-later">
          {@html t('When should we deliver the e-card?')}
        </label>
      </h3>

      <div class="uwdonation__form-row">
        <div class="uwdonation__col-50">
          <div class="uwdonation__form-group">
            <select id="send-now-later" bind:value={donation.dedication.send_when}>
              <option value="now">{t('Send now')}</option>
              <option value="later">{t('Send later')}</option>
            </select>
          </div>
        </div>
        <div class="uwdonation__col-50">
          <div class="uwdonation__form-group">
            {#if donation.dedication.send_when === 'now'}
              <p class="uwdonation__label-extra-help">{t('We’ll send your card as soon as your donation has been processed.')}</p>
            {:else}
              <input
                on:keydown={onChange}
                type="date"
                bind:value={donation.dedication.send_date}
                min={new Date().toISOString().split('T')[0]}
                required={donation.inTribute && donation.dedication.send_when === 'now'} />
            {/if}
          </div>
        </div>
      </div>

      <div class="uwdonation__form-group">
        <div class="uwdonation__form-check">
          <input type="checkbox" id="notify_sender" name="notify_sender" bind:checked={donation.dedication.notify_sender} />
          <label class="form-check-label" for="notify_sender">
            <b>
              {@html t('Please notify me when sent.')}
            </b>
          </label>
        </div>
      </div>

      <Ecards bind:ecards bind:donation />

    </div>

    <!-- Print -->
    <div style="display:{donation.dedication.type === 'print' ? 'block' : 'none'}">
      <h3>
        {@html t('What message should we include with your card?')}
      </h3>
      <div class="uwdonation__form-group">
        <label for="inHonourOf">
          {@html t('In honour of')}
        </label>
        <input
          on:keydown={onChange}
          type="text"
          id="inHonourOf"
          maxlength="250"
          required={donation.inTribute}
          bind:value={donation.dedication.in_memory_of}
          placeholder={t("Honouree's full name")} />
      </div>
      <div class="uwdonation__form-group">
        <label for="ecardFrom">
          {@html t('From')}
        </label>
        <input
          on:keydown={onChange}
          type="text"
          id="ecardFrom"
          maxlength="100"
          required={donation.inTribute}
          bind:value={donation.dedication.senders_name}
          placeholder={t('Enter name')} />
      </div>
      <div class="uwdonation__form-group">
        <textarea
          on:keydown={onChange}
          id="comments"
          bind:value={donation.dedication.senders_message}
          placeholder={t('Include a custom message (optional)')} />
        <small class="uwdonation__label-extra-help">{t('250 characters maximum')}</small>
      </div>
      <div class="uwdonation__form-check">
        <input type="checkbox" id="hideGiftAmount" name="hideGiftAmount" bind:checked={donation.dedication.hide_gift_amount} />
        <label class="form-check-label" for="hideGiftAmount">
          <b>
            {@html t('Please hide the gift amount.')}
          </b>
        </label>
      </div>
      <Ecards bind:ecards bind:donation />
    </div>

  </div>

  <iframe
    style="display:{donation.inTribute && showPreview ? 'block' : 'none'}; margin-top: 1rem; border: 2px solid #ebebeb; border-radius: 3px;
    padding: 0.5rem; height: 50vh; width: 100%;"
    src={showPreview ? previewSrc : ''}
    title={t('Preview')} />

  <div class="uwdonation__actions">
    <button type="button" class="uwdonation__prev-step-button" on:click={() => activeStep.set(steps.Amount)}>
      <img
        class="uwdonation__prev-arrow"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
        alt="Previous" />
      <span>
        {@html t('Back')}
      </span>
    </button>

    <button
      style="display:{donation.inTribute && donation.dedication.type !== 'none' ? 'block' : 'none'}"
      type="button"
      class="uwdonation__preview-button"
      on:click={() => (showPreview = !showPreview)}>
      {showPreview === false ? t('Preview card') : t('Close preview')}
    </button>

    <button class="uwdonation__next-step-button" type="submit" disabled={donation.inTribute && !donation.dedication.type}>
      <span>
        {@html t('Continue')}
      </span>
      <img
        class="uwdonation__next-arrow"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
        alt="Next" />
    </button>
  </div>
</form>
