<script>
  import { activeStep, steps } from '../state.store';
  import { t } from '../../utils';
</script>

<div>
  <ul class="uwdonation_steps">
    <li class="uwdonation_step" class:uwdonation_step--active={$activeStep === steps.Amount}>{t('Amount')}</li>
    <li class="uwdonation_step" class:uwdonation_step--active={$activeStep === steps.Options}>{t('Options')}</li>
    <li class="uwdonation_step" class:uwdonation_step--active={$activeStep === steps.Info}>{t('Information')}</li>
    <li class="uwdonation_step" class:uwdonation_step--active={$activeStep === steps.Payment}>{t('Payment')}</li>
  </ul>
</div>
