<script>
  import { t } from '../../utils';
  import { activeStep, steps, amount } from '../state.store';

  import AddressForm from '../components/AddressForm.svelte';
  import PaymentRequestButton from '../components/PaymentRequestButton.svelte';

  export let stripe;
  export let donation;

  let showPaymentRequestButton = false;

  function onFormSubmit(event) {
    activeStep.set(steps.Payment);
  }
</script>

<PaymentRequestButton {stripe} {donation} on:showMe={e => (showPaymentRequestButton = e)} />

<form class="uwdonation__form" on:submit|preventDefault={onFormSubmit}>
  <fieldset class="uwdonation__fieldset">
    <legend class="uwdonation__legend">
      {@html t('Contact Information')}
    </legend>
    <div class="uwdonation__form-row">
      <div class="uwdonation__col-50">
        <div class="uwdonation__form-group">
          <label for="email-address">
            {@html t('Email address')}
          </label>
          <input type="email" id="email-address" required bind:value={donation.email} />
        </div>
      </div>
      <div class="uwdonation__col-50">
        <div class="uwdonation__form-group">
          <label for="phone-number">
            {@html t('Phone')}
          </label>
          <input type="tel" id="phone-number" required bind:value={donation.phone} />
        </div>
      </div>
    </div>
  </fieldset>
  <fieldset class="uwdonation__fieldset">
    <legend class="uwdonation__legend">
      {@html t('Billing Address')}
    </legend>
    <AddressForm bind:address={donation} required={true} />
  </fieldset>

  <div class="uwdonation__actions">
    <button type="button" class="uwdonation__prev-step-button" on:click={() => activeStep.set(steps.Options)}>
      <img
        class="uwdonation__prev-arrow"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
        alt="Previous" />
      <span>
        {@html t('Back')}
      </span>
    </button>
    <button class="uwdonation__next-step-button" on:click={() => activeStep.set(steps.Info)}>
      <span>
        {@html t('Continue to payment')}
      </span>
      <img
        class="uwdonation__next-arrow"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
        alt="Next" />
    </button>
  </div>

</form>
