import { Readable, Writable, derived, writable } from 'svelte/store';

import { formatCurrency } from '../utils/format-currency';
import { env } from '../environments/environment';
import AppConfig from './config/app.config';

export interface State {
  amount: number;
  isMonthly: boolean;
  slug: string;
  paymentOptions: stripe.paymentRequest.StripePaymentRequestOptions;
}

export type Step = 'amount' | 'options' | 'info' | 'payment' | 'thanks';

export const steps: { [key: string]: Step } = {
  Amount: 'amount',
  Options: 'options',
  Info: 'info',
  Payment: 'payment',
  Thanks: 'thanks',
};

export const amounts: number[] = AppConfig.amounts;

// Writables
export const slug: Writable<string> = writable(AppConfig.defaultCampaign);
export const activeStep: Writable<Step> = writable(steps.Amount);
export const amount: Writable<number> = writable(null);
export const isMonthly: Writable<boolean> = writable(false);
export const errors: Writable<string[]> = writable([]);

// Readables
export const amountDspl: Readable<string> = derived(amount, $amount => formatCurrency($amount));
// TODO: Get actual fees %... 8% was arbitrarily chosen
export const feesDspl: Readable<string> = derived(amount, $amount => formatCurrency($amount * 0.08));

export const state: Readable<State> = derived(
  [amount, isMonthly, slug],
  ([amount$, isMonthly$, slug$]): State => ({
    amount: amount$,
    isMonthly: isMonthly$,
    slug: slug$,
    paymentOptions: {
      country: 'CA',
      currency: 'cad',
      total: {
        label: 'Donation',
        amount: amount$ * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    },
  })
);
