<script>
  import { derived } from 'svelte/store';

  import { t } from '../../utils';
  import { amountDspl, feesDspl, isMonthly } from '../state.store';

  export let donation;

  export const donationType = derived(isMonthly, $isMonthly => ($isMonthly ? 'Monthly' : 'Single'));
</script>

<div class="uwdonation-table-wrapper">
  <table style="margin-bottom: 20px;" class="uwdonation-table uwdonation-full-width">
    <tbody class="uwdonation-table">
      <tr class="uwdonation-tr">
        <th class="uwdonation-th uwdonation-text-left">
          {#if $isMonthly}
            {@html t('Monthly donation')}
          {:else}
            {@html t('Single donation')}
          {/if}
        </th>
        <td class="uwdonation-td uwdonation-text-right">
          <b>{$amountDspl}</b>
        </td>
      </tr>
    </tbody>
  </table>
  <p style="margin-bottom: 5px; display:flex;">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="uwdonation__icon uwdonation__paperclip">
      <path
        d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943
        117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473
        1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427
        319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062
        19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093
        88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256
        22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z" />
    </svg>

    <small>
      {#if $isMonthly}
        {@html t('Your charitable tax receipt for donations received throughout the taxation year will be provided to you by email by the end of February of the following year.')}
      {:else}
        {@html t('You will receive an official tax receipt by email for your generous donation.')}
      {/if}
    </small>

  </p>
  <p style="margin-bottom: 0; margin-top: 0; display:flex;">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="uwdonation__icon uwdonation__lock">
      <path
        d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5
        48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" />
    </svg>
    <small>
      {@html t('All transactions are secure and encrypted to protect your personal and financial information.')}
    </small>
  </p>
  {#if donation && donation.dedication.in_memory_of}
    <p style="margin-bottom: 0; margin-top: 0; display:flex;">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="heart"
        class="uwdonation__icon uwdonation__lock"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
          d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9
          207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
      </svg>
      <small>
        {@html t('This donation is in honour of ')}
        {donation.dedication.in_memory_of}.
      </small>
    </p>
  {/if}
</div>
