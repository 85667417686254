<script>
  import { createEventDispatcher } from 'svelte';

  import { formatCurrency } from '../../utils';
  import { amount as donationAmount } from '../state.store';

  export let amount;

  function onClick() {
    donationAmount.set(amount);
  }
</script>

<button class="uwdonation__amount-button" type="button" on:click={onClick} class:active={amount === $donationAmount} value={amount}>
  {formatCurrency(amount, 0)}
</button>
