<script>
  import { onMount } from 'svelte';
  import { CanadianProvince, Countries, Country, Provinces, States, t } from '../../utils';

  export let address;
  export let required;

  onMount(() => {
    address.name = address.name || null;
    address.address1 = address.address1 || null;
    address.address2 = address.address2 || null;
    address.city = address.city || null;
    address.country = address.country || Country.Canada;
    address.postal_code = address.postal_code || null;

    if (!address.state) {
      onCountryChange();
    }
  });

  function onCountryChange() {
    if (address.country === Country.Canada) {
      address.state = CanadianProvince.Saskatchewan;
    } else {
      address.state = States[0].key;
    }
  }
</script>

<div class="uwdonation__form-group">
  <label for="name">
    {@html t('Name')}
  </label>
  <input type="text" id="name" {required} bind:value={address.name} />
</div>

<div class="uwdonation__form-row">
  <div class="uwdonation__col-60">
    <div class="uwdonation__form-group">
      <label for="address1">
        {@html t('Address')}
      </label>
      <input type="text" id="address1" {required} bind:value={address.address1} />
    </div>
  </div>
  <div class="uwdonation__col-40">
    <div class="uwdonation__form-group">
      <label for="address-unit">
        {@html t('Apt. / Suite')}
        <span class="uwdonation__label-extra-help">
          {@html t('(optional)')}
        </span>
      </label>
      <input type="text" id="address-unit" bind:value={address.address2} />
    </div>
  </div>
</div>

<div class="uwdonation__form-group">
  <label for="city">
    {@html t('City / Town')}
  </label>
  <input type="text" id="city" {required} bind:value={address.city} />
</div>

<div class="uwdonation__form-row">
  <div class="uwdonation__col-33">
    <div class="uwdonation__form-group">
      <label for="country">
        {@html t('Country / Region')}
      </label>
      <select id="country" {required} bind:value={address.country} on:change={onCountryChange}>
        {#each Countries as country}
          <option value={country.value}>{t(country.label)}</option>
        {/each}
      </select>
    </div>
  </div>
  <div class="uwdonation__col-33">
    <div class="uwdonation__form-group">
      {#if address.country === Country.Canada}
        <label for="province">
          {@html t('Province')}
        </label>
        <select id="province" {required} bind:value={address.state}>
          {#each Provinces as province}
            <option value={province.value}>{t(province.label)}</option>
          {/each}
        </select>
      {:else if address.country === Country.USA}
        <label for="province">
          {@html t('State')}
        </label>
        <select id="province" {required} bind:value={address.state}>
          {#each States as state}
            <option value={state.value}>{t(state.label)}</option>
          {/each}
        </select>
      {/if}
    </div>
  </div>
  <div class="uwdonation__col-33">
    <div class="uwdonation__form-group">
      <label for="postal-code">
        {@html t('Postal / Zip code')}
      </label>
      <input type="text" id="postal-code" {required} bind:value={address.postal_code} />
    </div>
  </div>
</div>
