<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { t } from '../../utils';

  export let donation;

  const dispatcher = createEventDispatcher();

  onMount(() => {
    donation.submitted = true;
  });
</script>

<div style="text-align:center;">
  <h1>
    <span>
      {@html t('Thank you for showing your local love, ')}
    </span>
    <span style="white-space: nowrap;">{donation.name}.</span>
  </h1>
  <p>
    {@html t('Thank you for taking action to support our communities and help power change.')}
  </p>
  <p>
    <b>
      {@html t('A confirmation with additional details has been sent to you by email.')}
    </b>
  </p>
  <div class="uwdonation__actions">
    <button class="uwdonation__next-step-button uwdonation__donate-button" on:click={() => dispatcher('close')}>
      <span>
        {@html t('Close')}
      </span>
    </button>
  </div>
</div>
