const AppConfig = {
  targetSelector: '#uwdonation-client',
  registrationNumber: '119278372RR0001',
  contact: {
    fr: {},
    en: {
      address1: '1440 Scarth St',
      address2: 'Regina, SK, S4R 2E9',
      email: 'web@unitedwayregina.ca',
      phone: '306-757-5671',
    },
  },
  brand: {
    fr: {
      alt: '',
    },
    en: {
      alt: 'United Way Regina',
    },
  },
  defaultCampaign: 'uwr',
  amounts: [25, 50, 100, 250, 500],
  defaultAmount: 50,
};

export default AppConfig;
