import { getLocale } from './locale';

export function formatCurrency(amount: number, digits = 2): string {
  return amount.toLocaleString(getLocale(), {
    minimumFractionDigits: digits,
    currency: 'CAD',
    currencyDisplay: 'symbol',
    useGrouping: true,
    style: 'currency',
  });
}
