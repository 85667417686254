export enum Community {
  Regina = 'Regina',
  MooseJaw = 'MooseJaw',
  SwiftCurrent = 'SwiftCurrent',
  Weyburn = 'Weyburn',
}

export const communityDictionary: Record<Community, string> = {
  [Community.MooseJaw]: 'Moose Jaw',
  [Community.Regina]: 'Regina',
  [Community.SwiftCurrent]: 'Swift Current',
  [Community.Weyburn]: 'Weyburn',
};
