<script>
  import { errors } from '../state.store';
</script>

{#if $errors.length > 0}
  <ul class="uwdonation__error-list">
    {#each $errors as err}
      <li class="uwdonation__error">{err}</li>
    {/each}
  </ul>
{/if}
