import { env } from '../environments/environment';
import i18n from '../i18n/app.i18n.json';
import { Locale, getLocale } from './locale';

export const missingTranslations = [];

export function t(key: string): string {
  if (env.production === false && !i18n[key]) {
    console.log('Missing translation:', key);

    if (!missingTranslations.includes(key)) {
      missingTranslations.push(key);
    }
  }

  if (getLocale() === Locale.French) {
    return i18n[key] || key;
  } else {
    return key;
  }
}
