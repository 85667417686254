<script>
  import { onDestroy } from 'svelte';
  import { slide, fly } from 'svelte/transition';

  import * as Models from '../models';
  import { t, apiLang } from '../utils';
  import { amount, isMonthly, activeStep, steps } from './state.store';

  import AmountButton from './components/AmountButton.svelte';
  import AmountInput from './components/AmountInput.svelte';
  import DonationInfo from './components/DonationInfo.svelte';
  import Errors from './components/Errors.svelte';
  import Footer from './components/Footer.svelte';
  import StepIndicator from './components/StepIndicator.svelte';

  import AmountStep from './steps/AmountStep.svelte';
  import GiftOptionsStep from './steps/GiftOptionsStep.svelte';
  import InfoStep from './steps/InfoStep.svelte';
  import PaymentStep from './steps/PaymentStep.svelte';
  import ThankYouStep from './steps/ThankYouStep.svelte';

  export let attributes;
  export let bgImage;
  export let donation;
  export let ecards;
  export let causes;

  let contentPane;

  const stripe = Stripe(attributes.payment_gateway.public_key);

  const unsub = activeStep.subscribe(() => {
    if (contentPane && contentPane.scrollTo) {
      contentPane.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });

  onDestroy(() => {
    unsub();
  });

  function onClose() {
    window.location.hash = '';
  }
</script>

<div class="uwdonation-app">
  <div class="uwdonation__pane pane--image" style="overflow:hidden; position: relative;">
    <img
      on:load={event => (event.target.style.opacity = 1)}
      src={bgImage.default}
      alt
      style="opacity: 0; position: absolute; top: 50%; left: 50%; height: 100%; width: auto; transform: translate(-50%, -50%); transition:
      opacity 1s linear; max-width: none;" />
  </div>
  <div class="uwdonation__pane pane--content" bind:this={contentPane}>
    <img
      class="uwdonation__mobile-image"
      on:load={event => (event.target.style.opacity = 1)}
      src={bgImage.mobile}
      alt
      style="max-width: 100%;" />
    <div class="uwdonation__button-cont">
      <button class="uwdonation__button button--close" on:click={onClose}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc5NCcgaGVpZ2h0PScxNTAnIHZpZXdCb3g9JzAgMCAzMjAgNTEyJz48cGF0aCBzdHlsZT0iZmlsbDojODc4Nzg3IiBkPSdNMTkzLjk0IDI1NkwyOTYuNSAxNTMuNDRsMjEuMTUtMjEuMTVjMy4xMi0zLjEyIDMuMTItOC4xOSAwLTExLjMxbC0yMi42My0yMi42M2MtMy4xMi0zLjEyLTguMTktMy4xMi0xMS4zMSAwTDE2MCAyMjIuMDYgMzYuMjkgOTguMzRjLTMuMTItMy4xMi04LjE5LTMuMTItMTEuMzEgMEwyLjM0IDEyMC45N2MtMy4xMiAzLjEyLTMuMTIgOC4xOSAwIDExLjMxTDEyNi4wNiAyNTYgMi4zNCAzNzkuNzFjLTMuMTIgMy4xMi0zLjEyIDguMTkgMCAxMS4zMWwyMi42MyAyMi42M2MzLjEyIDMuMTIgOC4xOSAzLjEyIDExLjMxIDBMMTYwIDI4OS45NCAyNjIuNTYgMzkyLjVsMjEuMTUgMjEuMTVjMy4xMiAzLjEyIDguMTkgMy4xMiAxMS4zMSAwbDIyLjYzLTIyLjYzYzMuMTItMy4xMiAzLjEyLTguMTkgMC0xMS4zMUwxOTMuOTQgMjU2eicvPjwvc3ZnPg=="
          alt="Close" />
      </button>
    </div>
    <div class="uwdonation__steps" style="overflow-x:hidden;">
      {#if $activeStep === steps.Amount}
        <div in:fly={{ x: 500, delay: 500 }} out:fly={{ x: -500, duration: 250 }}>
          <div class="uwdonation__step">
            <StepIndicator />
            <AmountStep {...attributes.details} />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Options}
        <div in:fly={{ x: 500, delay: 500 }} out:fly={{ x: -500, duration: 250 }}>
          <div class="uwdonation__step">
            <StepIndicator />
            <Errors />
            <DonationInfo />
            <GiftOptionsStep bind:donation bind:attributes bind:ecards bind:causes />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Info}
        <div in:fly={{ x: 500, delay: 500 }} out:fly={{ x: -500, duration: 250 }}>
          <div class="uwdonation__step">
            <StepIndicator />
            <Errors />
            <DonationInfo bind:donation />
            <InfoStep {stripe} bind:donation />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Payment}
        <div in:fly={{ x: 500, delay: 500 }} out:fly={{ x: -500, duration: 250 }}>
          <div class="uwdonation__step">
            <StepIndicator />
            <Errors />
            <DonationInfo bind:donation />
            <PaymentStep {stripe} bind:donation amount={$amount} isMonthly={$isMonthly} campaignId={attributes.slug} />
          </div>
          <Footer />
        </div>
      {:else if $activeStep === steps.Thanks}
        <div in:fly={{ x: 500, delay: 500 }} out:fly={{ x: -500, duration: 250 }}>
          <div class="uwdonation__step">
            <ThankYouStep bind:donation on:close={onClose} />
          </div>
          <Footer />
        </div>
      {/if}
    </div>
  </div>
</div>
