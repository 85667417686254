import { env } from '../../environments/environment';
import { CampaignResponse } from '../../models';
import { apiLang } from '../locale';

export async function getCampaign(slug: string): Promise<CampaignResponse> {
  const response = await fetch(`${env.apiUrl}/campaigns/${slug}`, {
    headers: {
      'Accept-Language': apiLang(),
    },
  });
  const campaign: CampaignResponse = await response.json();

  return campaign;
}
