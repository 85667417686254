import { Donation, DonationAttributes } from '../../models';
import { Interval } from './interval.enum';
import { postDonation } from './post-donation';

export function handleStripeToken(
  stripeRes: stripe.TokenResponse,
  campaignId: string,
  donation: DonationAttributes,
  amount: number,
  isMonthly: boolean
): Promise<Response> {
  const payload: Donation = {
    id: null,
    type: 'donations',
    attributes: {
      ...donation,
      amount: amount,
      interval: isMonthly ? Interval.Month : Interval.None,
      token: stripeRes.token.id,
    },
  };

  return postDonation(campaignId, payload);
}
