<script>
  import { onDestroy, onMount } from 'svelte';
  import { getLocale, handleStripeToken, t } from '../../utils';
  import Spinner from '../components/Spinner.svelte';
  import { activeStep, errors, steps } from '../state.store';

  export let stripe;
  export let donation;
  export let campaignId;
  export let isMonthly;
  export let amount;

  let card;
  let processing = false;

  onMount(() => {
    const elements = stripe.elements({ locale: getLocale() });
    const style = {};
    card = elements.create('card', { style: style, hidePostalCode: true });
    card.mount('#card-element');
  });

  onDestroy(() => {
    if (card) {
      card.unmount();
    }
  });

  function onSubmit(event) {
    processing = true;
    errors.set([]);
    stripe.createToken(card).then(function(result) {
      if (result.error) {
        processing = false;
        // Inform the customer that there was an error.
        let errorElement = document.querySelector('#card-errors');
        errorElement.textContent = result.error.message;
      } else {
        handleStripeToken(result, campaignId, donation, amount, isMonthly)
          .then(res => {
            processing = false;
            activeStep.set(steps.Thanks);
          })
          .catch(() => {
            processing = false;
          });
      }
    });
  }
</script>

<div style="position: relative">
  <form class="uwdonation__form" class:uwdonation__processing={processing} on:submit|preventDefault={onSubmit}>

    <div class="uwdonation__form-row">
      <div class="uwdonation__form-group">
        <div id="card-element" />
        <small id="card-errors" />
      </div>
    </div>

    <div class="uwdonation__actions">
      <button type="button" class="uwdonation__prev-step-button" on:click={() => activeStep.set(steps.Info)}>
        <img
          class="uwdonation__prev-arrow"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
          alt="Previous" />
        <span>
          {@html t('Back')}
        </span>
      </button>
      <button type="submit" class="uwdonation__next-step-button">
        <span>
          {@html t('Complete donation')}
        </span>
        <img
          class="uwdonation__next-arrow"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNycgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDQ0OCA1MTInPjxwYXRoIGQ9J00zMTEuMDMgMTMxLjUxNWwtNy4wNzEgNy4wN2MtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDM4Ny44ODcgMjM5SDEyYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjEwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJoMzc1Ljg4N2wtODMuOTI4IDgzLjQ0NGMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxbDcuMDcxIDcuMDdjNC42ODYgNC42ODYgMTIuMjg0IDQuNjg2IDE2Ljk3IDBsMTE2LjQ4NS0xMTZjNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDMyOCAxMzEuNTE1Yy00LjY4Ni00LjY4Ny0xMi4yODQtNC42ODctMTYuOTcgMHonLz48L3N2Zz4="
          alt="Next" />
      </button>
    </div>
  </form>

  {#if processing}
    <div style="position: absolute; top: 50%; left:50%; transform: translate(-50%, -50%);">
      <Spinner />
    </div>
  {/if}
</div>
